import { createRoot, hydrateRoot } from 'react-dom/client';
import React from 'react';
import App from './components/App';
import './assets/styles/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const root = document.getElementById('root');
if (root.hasChildNodes()) {
  hydrateRoot(<App />, root);
} else {
  createRoot(root).render(<App />);
}
