import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// import Home from '../components/pages/Home';
// import History from '../components/pages/History';
// import Page404 from '../components/pages/Page404';
// import Apartments from '../components/pages/Apartments';
import UnderConstruction from '../components/pages/UnderConstruction';

function AppRoutes() {
    return (
        <Routes>
            {/* <Route path="/" element={<Navigate to="/en" replace />} />
            <Route path="/:lang" element={<Home />} />
            <Route path="/:lang/history" element={<History />} />
            <Route path="/:lang/apartments" element={<Apartments />} />
            <Route path="/:lang/apartments/green-and-grey-apartment" element={<Apartments />} />
            <Route path="/:lang/apartments/excellence_rooms" element={<Apartments />} /> */}
            {/* 404 PAGE */}
            {/* <Route path="/*" element={<Page404 />} /> */}
            <Route path="/*" element={<Navigate to="/under-construction" replace />} />
            <Route path="/under-construction" element={<UnderConstruction />} />
        </Routes>
    );
}

export default AppRoutes;