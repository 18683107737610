import React from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
// import Header from './layout/Header';
import AppRoutes from '../routes/AppRoutes';
// import Footer from './layout/Footer';
import { LanguageProvider } from '../context/LanguageContext';
import '../assets/styles/App.css';
import { SpeedInsights } from "@vercel/speed-insights/react";

const AppWrapper = () => {
  const location = useLocation();
  const initialLang = location.pathname.split('/')[1] || 'en';

  return (
    <LanguageProvider initialLang={initialLang}>
      {/* <Header /> */}
      < AppRoutes />
      {/* <Footer /> */}
      <SpeedInsights />
    </LanguageProvider>
  );
};

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AppWrapper />
    </BrowserRouter>
  );
}

export default App;
