import React from 'react';

const UnderConstruction = ({ data }) => {
    return (
        <div className='underConstructionPageWrap'>
            <div className='underConstructionContent'>
                <img src={`${process.env.PUBLIC_URL}/assets/images/under_construction.png`} alt="Under Construction" className='underConstructionImg' />
                <h1>Our website is<br />under construction</h1>
                <p className='underConstructionText'>
                    <br />
                    Please check back soon.
                </p>
            </div>
        </div>
    );
};

export default UnderConstruction;
