import React, { createContext, useEffect, useState } from 'react';

const LanguageContext = createContext();

const LanguageProvider = ({ children, initialLang }) => {
  const [language, setLanguage] = useState(() => {
    return initialLang || localStorage.getItem('language') || 'en';
  });

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  const value = {
    language,
    setLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };
